
import { defineComponent } from 'vue';
import { IonInput, IonGrid, IonRow, IonCol, IonButton } from '@ionic/vue';
import { Storage } from '@capacitor/storage';
export default defineComponent({
  data() {
    return {
      message: '',
    };
  },
  components: {
    IonInput, IonGrid, IonRow, IonCol, IonButton
  },
  methods: {
    doReset() {
      Storage.clear();
      this.message = 'All data cleared';
    },
  }
})
