
import { defineComponent } from 'vue';
import { IonIcon, IonButtons, IonToolbar, IonPage, IonContent, IonGrid, IonRow, IonCol, IonButton, IonHeader } from '@ionic/vue';
import { Storage } from '@capacitor/storage';
import { home } from 'ionicons/icons';
import { useIonRouter } from '@ionic/vue';

function rot13(message: string) {
    return message.replace(/[a-z]/gi, letter => String.fromCharCode(letter.charCodeAt(0) + (letter.toLowerCase() <= 'm' ? 13 : -13)));
} 

export default defineComponent({
  data() {
    const localStore = '';
    return {
      localStore,
      isLoading: false,
      message: ''
    };
  },
  components: {
    IonIcon, IonButtons, IonButton, IonToolbar, IonGrid, IonRow, IonCol, IonContent, IonPage, IonHeader
  },
  setup() {
    const ionRouter = useIonRouter();
    return {
      home, ionRouter
    }
  },
  methods: {
    async doReset() {
      const x = await Storage.clear();
      this.message = 'All data cleared';
      if (x === undefined) {
        location.reload();
      }
    },
    async getData() {
      await Storage.set({
        key: 'frca-cpd-date',
        value: JSON.stringify({})
      });
      const x = await Storage.set({
        key: 'frca-cpd-marked',
        value: JSON.stringify(false)
      });
      if (x === undefined) {
        location.reload();
      }
      this.message = 'Date reset';
    }
  },
  async created() {
    const localStore = {
      apiData: '',
      date: '',
      summary: '',
      darkmode: '',
      marked: '',
      score: ''
    }
    localStore.apiData = (await Storage.get({ key: 'frca-cpd-apiData' })).value || 'no data found';
    localStore.date = (await Storage.get({ key: 'frca-cpd-date' })).value || 'no data found';
    localStore.summary = (await Storage.get({ key: 'frca-cpd-summary' })).value || 'no data found';
    localStore.darkmode = (await Storage.get({ key: 'frca-cpd-darkmode' })).value || 'no data found';
    localStore.marked = (await Storage.get({ key: 'frca-cpd-marked' })).value || 'no data found';
    localStore.score = (await Storage.get({ key: 'frca-cpd-score' })).value || 'no data found';
    this.localStore = rot13(JSON.stringify(localStore, null, 2));
  }
})
