import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue'
import ResetPage from '../views/ResetPage.vue'
import ToolsPage from '../views/ToolsPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/reset',
    name: 'ResetPage',
    component: ResetPage
  },
  {
    path: '/tools',
    name: 'ToolsPage',
    component: ToolsPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
