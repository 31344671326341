
import { IonItem, IonAvatar, IonTitle, IonModal, IonHeader, IonToolbar, IonToggle, IonButtons, IonSpinner, IonChip, IonLabel, IonToast, IonProgressBar, IonText, IonCol, IonGrid, IonRow, IonIcon, IonContent, IonPage, IonButton } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { Storage } from '@capacitor/storage';
import { cog, shareSocialOutline, sunny, moon, informationCircle, close  } from 'ionicons/icons';
import axios from 'axios';
import { DateTime } from 'luxon'; 

const appVersion = '1.3.1';

let API = 'https://frcadaily-api.herokuapp.com/question';
if (process.env.NODE_ENV === 'development') {
  API = 'http://localhost:3000/question';
}

async function getApiData(api: string) {
  const apiData = await axios.get(api)
  return apiData.data;
}

export default defineComponent({
  name: 'HomePage',
  components: {
    IonItem,
    IonAvatar,
    IonTitle,
    IonModal,
    IonToolbar,
    IonHeader,
    IonToggle,
    IonButtons,
    IonSpinner,
    IonContent,
    IonPage,
    IonButton,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonProgressBar,
    IonToast,
    IonChip,
    IonLabel
  },
  data () {
    return {
      showAllTime: true,
      timer: '',
      timerInterval: 0,
      timePassed: 0,
      version: appVersion,
      open: false,
      darkMode: false,
      hasError: false,
      isLoading: true,
      apiData: {
        id: 0,
        question: '',
        stems: [{detail: '', answer: '', givenAnswer: '', explanation: ''}],
        tags: '',
        explanation: '',
        further_reading: '',
      },
      midnight: DateTime.utc().plus({ hours: 1, days: 1}).set({hour: 0, minute: 0, second: 0, millisecond: 0}),
      // midnight: DateTime.utc().set({hour: 21, minute: 12, second: 0, millisecond: 0}),
      date: DateTime.utc().plus({hours: 1}).toISO().slice(0, 10),
      marked: false,
      summary: '',
      score: {raw: '0/0', percentage: '0%', totalAnswered: 0, totalCorrect: 0},
    }
  },
  setup() {
    
    console.log('Hello you lovely person. Join SCATA :)');
    const isOpenRef = ref(false);
    const setOpen = (state: boolean) => isOpenRef.value = state;
    return {
      shareSocialOutline,
      sunny,
      moon,
      close,
      informationCircle,
      isOpenRef,
      cog,
      setOpen
    }
  },
  async created() {
    let { value } = await Storage.get({ key: 'frca-cpd-darkmode' });
    if (value === 'true') {
      this.darkMode = true;
    } else {
      this.darkMode = false;
    }
    const prefersDarkMode = window.matchMedia("(prefers-color-scheme:dark)").matches;
    if (prefersDarkMode) {

      this.darkMode = true;
    }
    if (document.body.classList.contains('dark') && !this.darkMode) {
      document.body.classList.toggle('dark', false)
    }
    if (!document.body.classList.contains('dark') && this.darkMode) {
      document.body.classList.toggle('dark', true)
    }
    value = (await Storage.get({ key: 'frca-cpd-apiData' })).value;
    if (value !== null) {
      this.apiData = JSON.parse(value);
    } else {
      // console.log('no data -- calling API');
      await getApiData(API).then(data => {
        this.apiData = data;
        this.isLoading = false;
        this.syncData(true);
      }).catch(err => {
        console.log(err);
        this.hasError = true;
        this.isLoading = false;
      });
    }
    value = (await Storage.get({ key: 'frca-cpd-date' })).value;
    if (value !== null) {
      console.log('stored date is ' + JSON.parse(value));
      console.log('current date is ' + this.date);
      if (JSON.parse(value) !== this.date) {
        console.log('date changed -- calling API');
        getApiData(API).then(data => {
          this.apiData = data;
          this.marked = false;
          this.score.raw = '';
          this.score.percentage = '';
          this.isLoading = false;
          this.syncData(true);
        }).catch(err => {
          console.log(err);
          this.hasError = true;
          this.isLoading = false;
        });
      } else {
        // console.log('date is same');
        this.isLoading = false;
      }
    } else {
      // console.log('no valid date data -- calling API');
      // check we've not already called the API
      if (this.isLoading) {
        getApiData(API).then(data => {
          this.apiData = data;
          this.isLoading = false;
          this.syncData(true);
        }).catch(err => {
          console.log(err);
          this.hasError = true;
          this.isLoading = false;
        });
      }
    }

    value = (await Storage.get({ key: 'frca-cpd-score' })).value;
    // console.log('score: ' + value);
    if (value !== null) {
      this.score = JSON.parse(value);
    } else {
      this.score = {raw: '0/0', percentage: '0%', totalAnswered: 0, totalCorrect: 0};
    }
    value = (await Storage.get({ key: 'frca-cpd-marked' })).value;
    // console.log('marked: ' + value);
    if (value !== null) {
      this.marked = JSON.parse(value);
    } else {
      this.marked = false;
    }
    value = (await Storage.get({ key: 'frca-cpd-summary' })).value;
    // console.log('summary: ' + value);
    if (value !== null) {
      this.summary = JSON.parse(value);
    } else {
      this.summary = '';
    }
  },
  watch: {
    async timePassed() {
      const now = DateTime.utc().plus({hours: 1});
      const midnight = this.midnight;
      const output: any = now.until(midnight).toDuration([ 'hours', 'minutes', 'seconds']); 

      if (output.values) {
        const r = {
          hours: output.values.hours.toString(),
          minutes: output.values.minutes.toString(),
          seconds: Math.floor(output.values.seconds).toString()
        }

        if (output.values.seconds < 10) {
          r.seconds = `0${r.seconds}`
        }
        this.timer = `New question in ${r.hours} hr ${r.minutes} min ${r.seconds} sec`;
      } else {
        this.isLoading = true;
        await getApiData(API).then(data => {
          this.date = DateTime.utc().plus({hours: 1}).toISO().slice(0, 10);
          this.midnight = DateTime.utc().plus({ hours: 1, days: 1}).set({hour: 0, minute: 0, second: 0, millisecond: 0}),
          this.apiData = data;
          this.marked = false;
          this.summary = '';
          this.isLoading = false;
          this.syncData(true);
        }).catch(err => {
          console.log(err);
          this.hasError = true;
          this.isLoading = false;
        });
      }
    }
  },
  computed: {
    linkified() {
      const link: string = this.apiData.further_reading;
      // console.log({link})
      if (!link) { return link }
      if (link.startsWith('http')) {
        return 'Further reading: <a href="' + link + '">' + link + '</a>';
      }
      if (link.length > 0) {
        return 'Further reading: ' + link;
      }
      return link
    },
  },
  mounted() {
    this.startTimer();
  },
  methods: {
    summaryText() {
      if (this.showAllTime) {
        return this.summary
      }
      return this.summary.substring(0, this.summary.indexOf('[') - 1)
    },
    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },
    swapAllTime() {
      this.showAllTime = !this.showAllTime;
    },
    async swapTheme() {
      this.darkMode = !this.darkMode;
      // console.log({dm: this.darkMode})
      // console.log('body contains dark?', document.body.classList.contains('dark'))
      if (document.body.classList.contains('dark') && !this.darkMode) {
        document.body.classList.toggle('dark', false)
      }
      if (!document.body.classList.contains('dark') && this.darkMode) {
        document.body.classList.toggle('dark', true)
      }
      await Storage.set({
        key: 'frca-cpd-darkmode',
        value: JSON.stringify(this.darkMode)
      });
    },
    isItDark() {
      return document.body.classList.contains('dark')
    },
    async share(event: any) {
      let text: string = this.summary;
      if (!this.showAllTime) {
        text.substring(0, text.indexOf('[') - 1)
      }
      const shareData = {
        title: 'FRCA daily',
        text: text,
        url: 'https://frcadaily.co.uk',
      }
      try {
        await navigator.share(shareData);
        // console.log(x);
        // console.log('Triggered share...');
      } catch(err) {
        console.log('Could not share -- trying clipboard fallback');
        event.preventDefault();
        (this.$refs as any).textToCopy.focus();
        (this.$refs as any).textToCopy.select();
        document.execCommand('copy');
        this.isOpenRef = true;
      }
    },
    allTimeProportion() {
      return this.score.totalCorrect / this.score.totalAnswered;
    },
    mark() {
      let marks = 0;
      let scoreCard = '';
      for (let stem of this.apiData.stems) {
        if (stem.givenAnswer === stem.answer.slice(0,1)) {
          marks++;
          scoreCard += '✅';
        } else {
          scoreCard += '❌';
        }
      }
      this.marked = true;
      this.score = {
        raw:`${marks}/${this.apiData.stems.length}`,
        percentage: `${Math.round((marks / this.apiData.stems.length) * 100)}%`,
        totalAnswered: this.apiData.stems.length + this.score.totalAnswered,
        totalCorrect: marks + this.score.totalCorrect
      };
      this.summary = `FRCA daily #${this.apiData.id}\n${scoreCard}\nI scored ${this.score.percentage} today\n[All-time score: ${Math.round(this.allTimeProportion() * 100)}% of ${this.score.totalAnswered} questions]`;
      this.syncData(false);
    },
    setAnswer(item: number, givenAnswer: string) {
      if (!this.marked) {
        this.apiData.stems[item].givenAnswer = givenAnswer;
        this.syncData(false);
      }
    },
    getFill(item: number, option: string) {
      if (this.apiData.stems[item].givenAnswer === option) {
        return 'solid';
      } else {
        return 'outline';
      }
    },
    tags() {
      if (!this.apiData.tags) {
        return ['unclassified']
      }
      return this.apiData.tags.split('" "').map(tag => tag.replace(/(^"|"$)/g, ''));
    },
    async syncData(flag: boolean) {
      // flag is true is API has been called
      if (flag) {
        await Storage.set({
          key: 'frca-cpd-apiData',
          value: JSON.stringify(this.apiData)
        });
        await Storage.set({
          key: 'frca-cpd-date',
          value: JSON.stringify(this.date)
        });
      }
      await Storage.set({
        key: 'frca-cpd-marked',
        value: JSON.stringify(this.marked)
      });
      await Storage.set({
        key: 'frca-cpd-score',
        value: JSON.stringify(this.score)
      });
      await Storage.set({
        key: 'frca-cpd-summary',
        value: JSON.stringify(this.summary)
      });
    },
    answer(index: number) {
      if (this.apiData.stems[index].givenAnswer === this.apiData.stems[index].answer.slice(0,1)) {
        return '✅';
      } else {
        return '❌ - ' + (this.apiData.stems[index].answer.slice(0,1) === 'T' ? 'True' : 'False');
      }
    },
    dateShort() {
      return this.date.toLocaleString();
    },
    randomAnaestheicColour() {
      const colours = [
        'induction_agent',
        'antiemetic',
        'hypnotic',
        'neuromuscular_blocker',
        'neuromuscular_reversal',
        'vasopressor',
        'anticholinergic',
        'local_anesthetics',
        'opioid',
        'depolarizing_neuromuscular_blocker'
        ];
      const gameBeginning = new Date('19 February 2022').setHours(0, 0, 0, 0);
      const dateIndex = (beginning: any, date: any) =>
        Math.round((date.setHours(0, 0, 0, 0) - beginning) / 864e5);
      const coloursIndex = (date: any) =>
        (dateIndex(gameBeginning, date) % colours.length);
      return colours[coloursIndex(new Date())];
    }
  },
});
